import { createRouter, createWebHistory } from 'vue-router'
import { bang, getMessageMap } from '@grantstreet/psc-vue/utils/i18n.ts'
import { formatParams } from '@grantstreet/psc-js/utils/routing.js'
import VueCookies from 'vue-cookies'
import { sentryException } from '@grantstreet/govhub-vue'
import { handleDynamicImport } from '@grantstreet/govhub-vue/src/dynamic-import-helpers.ts'
import { defineAsyncComponentWithHandlers } from '@grantstreet/psc-vue/utils/async-components.ts'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import { stripKeys } from '@grantstreet/psc-js/utils/objects.ts'
import {
  configRoutes,
  getReportsLinkMeta,
  payableSearchRoutes,
  bulkAddToCartRoutes,
  taxsysIframeRoutes,
  siteUsesHomepage,
  siteUsesRedirectOnly,
  siteUsesFormsOnly,
  isStubSite,
  isEpsRoute,
  bypassBasicAuth,
  needsDemoSiteAuth,
  needsAuth,
  isMissingToSiteParam,
  isUnknownSite,
  isTsReportsRoute,
  isUnknownDefaultSiteRoute,
  shouldRedirectClientOnlySite,
  isUnknownClientOnlyRoute,
  isSiteDisabledInProd,
  formatTitle,
} from '@grantstreet/govhub-vue/src/utils.ts'
import { currentBaseUrl } from '@grantstreet/govhub-vue/src/static-build-helpers.ts'

// These are the most common route entrypoints to GovHub. Future entrypoints
// to GovHub should also be added here.
// Ideally, it would be nice to dynamically import these as well. But, that
// increases the cumulative layout shfit the user experiences.
// That makes the site loading appear more disorienting.
import HomePage from './views/HomePage.vue'
import CheckoutLanding from '@grantstreet/govhub-vue/src/views/CheckoutLanding.vue'
import SearchPage from './views/SearchPage.vue'
import LoadFailure from './views/LoadFailure.vue'
import ErrorPage from './views/ErrorPage.vue'
import DynamicLoadErrorPage from './views/DynamicLoadErrorPage.vue'
import VerifyEmail from './views/VerifyEmail.vue'

const FormsPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-forms',
  // TODO PSC-22260: Get rid of cyclical import once we move router to govhub-ui
  // eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
  import(/* webpackChunkName: "govhub-ui-forms" */'@grantstreet/govhub-ui/src/views/FormsPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const ConfirmationPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-checkout',
  import(/* webpackChunkName: "govhub-ui-checkout" */'@grantstreet/govhub-vue/src/views/Checkout/ConfirmationPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const NonprodSignIn = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-nonprod-sign-in',
  import(/* webpackChunkName: "govhub-ui-nonprod-sign-in" */'@grantstreet/govhub-vue/src/views/NonprodSignInPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const Receipt = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-checkout',
  import(/* webpackChunkName: "govhub-ui-checkout" */'@grantstreet/govhub-vue/src/views/Receipt.vue'),
), { errorComponent: DynamicLoadErrorPage })
const ReceiptRedirect = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-checkout',
  import(/* webpackChunkName: "govhub-ui-checkout" */'@grantstreet/govhub-vue/src/views/ReceiptRedirect.vue'),
), { errorComponent: DynamicLoadErrorPage })
const MyDashboard = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-my-dashboard',
  import(/* webpackChunkName: "govhub-ui-my-dashboard" */'./views/MyDashboardPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const TaxSys = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-taxsys',
  // TODO PSC-22260: Get rid of cyclical import once we move router to govhub-ui
  // eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
  import(/* webpackChunkName: "govhub-ui-taxsys" */'@grantstreet/govhub-ui/src/views/TaxSysPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const UploadPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-bulk-add-to-cart',
  import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'@grantstreet/govhub-vue/src/views/BulkAddToCart/UploadPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const ReviewPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-bulk-add-to-cart',
  import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'@grantstreet/govhub-vue/src/views/BulkAddToCart/ReviewPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const PaymentOptionsPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-bulk-add-to-cart',
  import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'@grantstreet/govhub-vue/src/views/BulkAddToCart/PaymentOptionsPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const SuccessPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-bulk-add-to-cart',
  import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'@grantstreet/govhub-vue/src/views/BulkAddToCart/SuccessPage.vue'),
), { errorComponent: DynamicLoadErrorPage })
const EbillingPage = defineAsyncComponentWithHandlers(() => handleDynamicImport(
  'govhub-ui-e-billing',
  import(/* webpackChunkName: "govhub-ui-e-billing" */'@grantstreet/govhub-vue/src/views/EbillingSubscription.vue'),
), { errorComponent: DynamicLoadErrorPage })

export default (store) => {
  const router = createRouter({
    history: createWebHistory(currentBaseUrl()),
    scrollBehavior (to, _from, savedPosition) {
      // Implement scroll to anchor per the vue-router v4 docs
      if (to.hash) {
        return { el: to.hash }
      }
      // Scroll to top when navigating to new routes
      return savedPosition || { left: 0, top: 0 }
    },
    routes: [
      {
        // Only enabled on sunshine/demo. Path/route subject to change
        path: '/:app(dmv)?/:client(sunshine)/:site(demo)?/taxsys/:taxsysSlugs(.*)*',
        name: 'taxsys',
        meta: {
          fullWidth: true,
          title: {
            en: 'TaxSys',
            es: 'TaxSys',
          },
          disableForEPS: true,
        },
        component: TaxSys,
        props: true,
      },
      {
        // ⚠️ CAUTION: This route is an Embedded Public Site entry path.
        // This path should not be changed without coordination of all EPS clients.
        path: '/:app(dmv)?/:client/:site?/checkout',
        name: 'checkout',
        meta: {
          hideCartIcon: true,
          fullWidth: true,
          title: getMessageMap('checkout.title'),
        },
        component: CheckoutLanding,
        props: true,
      },
      {
        path: '/:app(dmv)?/:client/:site?/confirmation',
        name: 'confirmation',
        component: ConfirmationPage,
        meta: {
          hideCartIcon: true,
          fullWidth: true,
          title: getMessageMap('checkout.confirmation.title.page'),
        },
        beforeEnter (to) {
          if (!store.getters['Cart/chosenTender']) {
            return {
              name: 'checkout',
              params: formatParams(to.params),
            }
          }
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/receipt/:receiptId',
        name: 'receipt',
        component: Receipt,
        meta: {
          title: getMessageMap('payments.receipt'),
          hideCartIcon: true,
          redirectHomeOnLogout: true,
          fullWidth: true,
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/receipt/:receiptId/redirect',
        name: 'receipt-redirect',
        component: ReceiptRedirect,
        meta: {
          title: getMessageMap('payments.receipt'),
          redirectHomeOnLogout: true,
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/redirect/:cartId',
        name: 'redirect',
        meta: {
          hideCartIcon: true,
          title: getMessageMap('cart.default'),
        },

        // Only displayed if main-govhub.ts cannot handle the redirect:
        component: ErrorPage,
        props: {
          type: 'cartError',
          isRedirect: true,
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/verify-email',
        name: 'verify-email',
        component: VerifyEmail,
      },

      // Errors:
      {
        path: '/:app(dmv)?/:client/:site?/load-failure',
        name: 'load-failure',
        component: LoadFailure,
        meta: {
          title: getMessageMap('error.default'),
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/rate-limit-error',
        name: 'rate-limit-error',
        component: ErrorPage,
        props: {
          type: 'rateLimitError',
        },
        meta: {
          skipModal: true,
          hideCartIcon: true,
          title: getMessageMap('error.default'),
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/login-error',
        name: 'login-error',
        component: ErrorPage,
        props: {
          type: 'loginError',
        },
        meta: {
          skipModal: true,
          hideCartIcon: true,
          title: getMessageMap('error.default'),
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/cart-error',
        name: 'cart-error',
        component: ErrorPage,
        props: {
          type: 'cartError',
        },
        meta: {
          skipModal: true,
          hideCartIcon: true,
          title: getMessageMap('error.default'),
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/redirect-error',
        name: 'redirect-error',
        component: ErrorPage,
        props: {
          type: 'cartError',
          isRedirect: true,
        },
        meta: {
          skipModal: true,
          hideCartIcon: true,
          title: getMessageMap('error.default'),
        },
      },
      {
        // This route is used by cart-redirect BE only. PSC-3858 will remove it
        path: '/:app(dmv)?/:client/:site?/limit-exceeded',
        name: 'limitExceeded',
        component: ErrorPage,
        props: {
          type: 'limitExceeded',
          hideSupportLink: true,
        },
        meta: {
          skipModal: true,
          hideCartIcon: true,
          title: bang(getMessageMap('error.limitExceeded.title')),
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/network-error',
        name: 'networkError',
        component: ErrorPage,
        props: {
          type: 'networkError',
          hideSupportLink: true,
        },
        meta: {
          skipModal: true,
          hideCartIcon: true,
          title: bang(getMessageMap('error.networkError.title')),
        },
      },
      {
        path: '/:app(dmv)?/:client?/:site?/nonprod-sign-in',
        name: 'nonprodSignIn',
        component: NonprodSignIn,
        meta: {
          disableForEPS: true,
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?',
        name: 'home',
        component: HomePage,
        alias: '/:client',
        meta: {
          fullWidth: true,
          fullWidthFooter: true,
          disableForEPS: true,
        },
        beforeEnter ({ params }) {
          // For search sites continue as usual
          if (siteUsesHomepage()) {
            return
          }

          console.warn('Home page is disabled for non-search sites')
          // For cart redirect sites return to checkout
          if (siteUsesRedirectOnly()) {
            return {
              name: 'checkout',
              params,
            }
          }

          // For forms only sites go to forms home
          if (siteUsesFormsOnly()) {
            // Until PSC-9031, this will 404.
            return {
              name: 'forms',
              params,
            }
          }

          // Otherwise redirect to the marketing redirect route
          return { path: '/' }
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/ebilling/:subscriptionId/:action/:authCode?',
        name: 'e-billing-subscription',
        component: EbillingPage,
        meta: {
          hideFooter: true,
          hideCartIcon: true,
        },
      },
      {
        path: '/:app(dmv)?/:client/:site?/:pathMatch(.*)*',
        name: 'error',
        component: ErrorPage,
        props: {
          type: 'notFound',
        },
        meta: {
          hideCartIcon: true,
          title: getMessageMap('error.notFound.title'),
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'marketing-redirect',
        beforeEnter () {
          sentryException(new Error(`Bouncing user from ${window.location} to GSG homepage`), {
            level: 'warning',
          })
          window.location.replace('https://www.grantstreet.com')
        },
      },
      ...configRoutes({
        useMyDashboard: {
          path: '/:app(dmv)?/:client/:site?/my-dashboard',
          name: 'my-dashboard',
          component: MyDashboard,
          meta: {
            title: getMessageMap('topnav.dashboard'),
          },
        },
        useMySavedItems: {
          path: '/:app(dmv)?/:client/:site?/my-saved-items',
          name: 'my-saved-items',
          redirect: { name: 'my-dashboard', hash: '#my-items' },
        },
        useMyPayments: {
          path: '/:app(dmv)?/:client/:site?/my-payments',
          name: 'my-payments',
          redirect: { name: 'my-dashboard', hash: '#my-payments' },
        },
        useLogin: {
          path: '/:app(dmv)?/:client/:site?/my-profile',
          name: 'my-profile',
          redirect: { name: 'my-dashboard', hash: '#my-settings' },
        },
        useForms: {
          path: '/:app(dmv)?/:client/:site?/forms/:slug?',
          name: 'forms',
          meta: {
            title: getMessageMap('govhub.forms'),
            hideFooter: false,
            narrowPage: true,
            // TODO PSC-9031 linkInNav once more
            linkInNav: false,
            navOrder: 2000,
            navIcon: 'clipboard',
          },
          component: FormsPage,
          props: true,
        },

        // These routes and settings are intended to be temporary. CX will eventually
        // develop a generalized mechanism to enable settings as cards on the homepage
        // and nav links in the menu.
        // The getters for these TaxSys settings check that a flag.
        useBtExpress: {
          path: '/:app(dmv)?/:client/:site?/btexpress/:taxsysSlugs(.*)*',
          name: 'btexpress',
          component: TaxSys,
          meta: {
            taxsysPath: 'btexpress',
            title: {
              // TaxSys isn't localizing yet
              en: 'BTExpress™️',
              es: 'BTExpress™️',
            },
          },
        },
        useReports: {
          path: '/:app(dmv)?/:client/:site?/reports/:taxsysSlugs(.*)*',
          name: 'taxsys-reports',
          component: TaxSys,
          meta: {
            taxsysPath: 'reports',
            title: {
              en: 'Reports',
              es: 'Reportes',
            },
            navIcon: 'line-graph',
            linkInNav: true,
            ...getReportsLinkMeta(),
          },
        },
      }),
      ...payableSearchRoutes(SearchPage),
      ...bulkAddToCartRoutes({
        uploadPageComponent: UploadPage,
        reviewPageComponent: ReviewPage,
        paymentOptionsPageComponent: PaymentOptionsPage,
        successPageComponent: SuccessPage,
      }),
      ...taxsysIframeRoutes(TaxSys),
    ],
  })

  store.commit('PayHub/setRoutes', router.getRoutes())

  router.beforeEach(async (to, from) => {
    // Formatting is critical when reading params like client and site
    const {
      client: toClient,
      site: toSite,
    } = formatParams(to.params)

    const {
      client: fromClient,
      site: fromSite,
    } = formatParams(from.params)

    if (!toClient) {
      return
    }

    // If this is a named route like { name: "home" }, the client-only version
    // of the URL will be the one matched first, so `to` will not have a site.
    // This means that the user will be incorrectly routed to /sunshine instead
    // of /sunshine/demo. This block restores the `site` param from `from`.
    if (isMissingToSiteParam(to, from)) {
      return {
        ...to,
        params: {
          ...to.params,
          site: fromSite,
        },
      }
    }

    // Don't render stub sites
    if (isStubSite() && to.path !== '/') {
      return { path: '/' }
    }

    // Some routes are disabled when govhub runs as an embedded public site.
    // Navigation to certain routes such as the home page must be intercepted.
    // When the user navigates to disabled routes, they will be redirected to
    // the cart page, and an error will be logged to sentry.
    if (isEpsRoute(to)) {
      // Redirects to "home" are expected for ebilling workflows so
      // only error if an attempt is made to redirect the user elsewhere
      if (to.name !== 'home') {
        const error = new Error(`EPS user tried to navigate to invalid route (${to.fullPath}). They were redirected to the checkout page.`)

        sentryException(error, {
          level: 'warning',
        })
      }

      return {
        name: 'property-tax',
        params: {
          ...to.params,
          client: fromClient,
          site: fromSite,
        },
      }
    }

    // XXX PSC-11644
    // If we introduce a test site with a default URL this will break
    const signedInKey = needsDemoSiteAuth()
      ? `${toClient}.${toSite || toClient}`
      : 'Nonprod'

    // To improve smoothness of redirects into our site during demos, this will bypass the basic auth modal
    // as long as the bypassBasicAuth site setting is toggled true, we're in demo, and coming from a redirect.
    const isDemoRedirect = bypassBasicAuth() && window.location.pathname.match(/redirect/)

    const signedIn = store.getters['PayHub/signedInToNonprod'][signedInKey] ||
      // @ts-expect-error Ignoring typescript error because VueCookies is typed
      // wrong. See: https://github.com/cmp-cc/vue-cookies/issues/76
      VueCookies.get(signedInKey)

    if (to.name !== 'nonprodSignIn' && needsAuth(to) && !signedIn && !isStubSite() && !isDemoRedirect) {
      const redirectCartMatch = window.location.pathname.match('^/[^/]+(?:/[^/]+)?/redirect/([^/]+)')
      return {
        name: 'nonprodSignIn',
        params: to.params,
        state: {
          query: to.query,
          additionalParams: { ...to.params },
          redirectCartId: redirectCartMatch ? redirectCartMatch[0] : null,
        },
        query: {
          returnTo: to.name?.toString(),
          returnAt: to.hash.toString(),
        },
      }
    }

    if (isUnknownSite()) {
      console.warn(`Unknown site '${toSite}' for client '${toClient}'`)
      return { path: '/' }
    }

    // If the site is reports, that means Vue Router caught 'reports' as
    // the site when the user was trying to view a TaxSys report.
    // This will redirect them to the correct place.
    if (isTsReportsRoute(to)) {
      return {
        name: 'taxsys-reports',
        params: {
          client: toClient,
          taxsysSlugs: to.params.taxsysSlugs,
        },
        query: to.query,
      }
    }

    // Handle Default Site Clients navigating to an unknown route.
    // There is a site in the URL but it doesn't match any site or module.
    // Redirect to the default site home.
    if (isUnknownDefaultSiteRoute(to)) {
      return {
        name: 'home',
        params: {
          client: toClient,
        },
      }
    }

    // Handle client-only URLs (e.g., /sacramento)
    // This is supposed to be a client-only url...
    // Redirect e.g. /sacramento/sacramento to just /sacramento
    if (shouldRedirectClientOnlySite(to)) {
      return {
        ...stripKeys(to, key => ['path', 'fullPath'].includes(key)),
        params: stripKeys(to.params, key => key === 'site'),
      }
    }
    // The user navigated to an unknown route, so send them home (there is
    // no good way to redirect to our 404 page in this case without an
    // infinite loop)
    else if (isUnknownClientOnlyRoute(to)) {
      return {
        name: 'home',
        params: {
          client: toClient,
        },
      }
    }

    if (isSiteDisabledInProd(to)) {
      console.warn(`Site '${toSite}' for client '${toClient}' is not enabled`)
      return { path: '/' }
    }
  })

  router.beforeResolve((to, from) => {
    // If configs were going to change they have by this point

    // TODO: PSC-9145 consolidate this title behavior with the @unhead/vue logic
    // in App.vue and HomePage.vue. AFAIK the title logic in App.vue is
    // canonical.
    const title = formatTitle(to, store.getters['PayHub/clientTitle'])

    if (title) {
      document.title = title
    }

    store.dispatch('PayHub/logRequest', {
      url: to.path,
      referer: from.matched?.length > 0 ? `${window?.location?.origin}${from.fullPath}` : document?.referrer,
    })
  })

  router.afterEach((to) => {
    // Emit this route changed event after every navigation.
    // The govhub installer will listen to this event when running as an embedded
    // public site.
    EventBus.$emit('routeChange', to)
  })

  return router
}
