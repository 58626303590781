import { configGetters } from '@grantstreet/psc-config'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    __MOCK_IS_COUNTY_TAXES__: boolean
  }
}

// note: the keys:
// 'tax', 'dmv' and 'redirectToCountyTaxes'
// are values attached to payable sources via the productOwnership
// or the urlAppMapping payable source settings.

export const pex = 'pex'
export const tax = 'tax'
export const dmv = 'dmv'
export const countyTaxes = 'redirectToCountyTaxes'

export const isOnCountyTaxes = () =>
  window.location.hostname.includes('county-taxes.net') || window.__MOCK_IS_COUNTY_TAXES__

export const getFilteringContext = route => {
  const { payablesActionControlLists } = configGetters
  if (!Object.keys(payablesActionControlLists).length) {
    return ''
  }
  const app = route.params.app
  if (isOnCountyTaxes() && !app && payablesActionControlLists?.[countyTaxes]) {
    return countyTaxes
  }

  return !app ? pex : app
}
