import { sentryFactory } from '@grantstreet/sentry'
import BaseSentry, { wrapSentryForProject } from '@grantstreet/sentry-multiplex'
import { isProd, GSG_ENVIRONMENT } from '@grantstreet/psc-environment'
import { BooleanFlag } from '@grantstreet/flag'
import { clientAndSiteFromUrl } from './installation/setup-config.ts'

const dsns = {
  // govhub-ui-prod
  prod: 'https://10c054b10b974c81b73423a0d835e640@o168195.ingest.sentry.io/1432778',
  // govhub-ui-nonprod
  nonprod: 'https://0b277d98b53c4c4ab0748b2ede995898@o168195.ingest.sentry.io/5827657',
}

// Export the @grantstreet/sentry way of doing things
export const {
  sentryException: oldSentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns,
  // Only send 50% of govhub-ui events to Sentry, to protect our Sentry quotas.
  // At the time of implementation (2023-11-01), this should take the rate down
  // from 600/hr to 300/hr.
  clientOptions: {
    sampleRate: 0.5,
  },
})

// Also export the @grantstreet/sentry-multiplex way of doing things
const Sentry = wrapSentryForProject('govhub-vue', {
  dsn: () => {
    // This DSN function is special: We may be called on to initialize
    // Sentry before we know what environment we're in. In that case, we
    // want to use the prod environment to make sure we don't miss
    // something if it turns out we _are_ in prod.
    let prod = true
    try {
      prod = isProd()
    }
    catch (error) {
      // If we're being initialized before isProd() works, we must be
      // about to send an event. Add a note to mention that we weren't
      // able to check isProd() in case it's relevant to the problem.
      BaseSentry.addBreadcrumb({
        message: 'Unable to check if prod. Defaulting to using prod Sentry DSN.',
        level: 'warning',
      })
    }

    return prod ? dsns.prod : dsns.nonprod
  },
  // Only send 50% of govhub-ui events to Sentry, to protect our Sentry quotas.
  // At the time of implementation (2023-11-01), this should take the rate down
  // from 600/hr to 300/hr.
  sampleRate: 0.5,
})
export default Sentry

let enableSentryMultiplex = false
let checkedFeatureFlag = false
// Export a helper to pick which one to use based on a feature flag
export const sentryException = async (...args) => {
  if (!checkedFeatureFlag) {
    // Get the client/site from the URL because we may be called before
    // Config is completely set up.
    const { client, site } = clientAndSiteFromUrl()

    const flag = await BooleanFlag.fetch('ticket/PSC-22904', { env: GSG_ENVIRONMENT, client, site })
    checkedFeatureFlag = true
    enableSentryMultiplex = flag.value
  }
  return enableSentryMultiplex ? Sentry.captureException(...args) : oldSentryException(...args)
}
