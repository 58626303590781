import { sentryFactory } from '@grantstreet/sentry'
import { wrapSentryForProject } from '@grantstreet/sentry-multiplex'
import { GSG_ENVIRONMENT, isProd } from '@grantstreet/psc-environment'
import { BooleanFlag } from '@grantstreet/flag'
import { configGetters as config } from '@grantstreet/psc-config'

const dsns = {
  // cart-vue-prod & -nonprod projects
  prod: 'https://27ec38bbb13743fb8529d6e55e7ce9c9@o168195.ingest.sentry.io/4504600086511616',
  nonprod: 'https://da9b0ab970284e239789d43788c9274a@o168195.ingest.sentry.io/4504600110366720',
}

// Export the @grantstreet/sentry way of doing things
export const {
  sentryException: oldSentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns,

  // Only send 20% of cart-vue events to Sentry, to protect our Sentry quotas.
  // At the time of implementation (2023-11-01), this should take the rate down
  // from 750/hr to 150/hr.
  clientOptions: {
    sampleRate: 0.2,
  },
})

// Also export the @grantstreet/sentry-multiplex way of doing things
const Sentry = wrapSentryForProject('cart-vue', {
  dsn: () => isProd() ? dsns.prod : dsns.nonprod,
  // Only send 20% of cart-vue events to Sentry, to protect our Sentry quotas.
  // At the time of implementation (2023-11-01), this should take the rate down
  // from 750/hr to 150/hr.
  sampleRate: 0.2,
})
export default Sentry

let enableSentryMultiplex = false
let checkedFeatureFlag = false
// Export a helper to pick which one to use based on a feature flag
export const sentryException = async (...args) => {
  if (!checkedFeatureFlag) {
    const flag = await BooleanFlag.fetch('ticket/PSC-22904', { env: GSG_ENVIRONMENT, client: config.client, site: config.site })
    checkedFeatureFlag = true
    enableSentryMultiplex = flag.value
  }
  return enableSentryMultiplex ? Sentry.captureException(...args) : oldSentryException(...args)
}
