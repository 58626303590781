import { ComponentPublicInstance, Plugin } from 'vue'
import { BrowserClient, BrowserOptions, Hub } from '@sentry/browser'
import { GSG_ENVIRONMENT, isProd, environmentStrings } from '@grantstreet/psc-environment/environment.js'
// We can't list @grantstreet/psc-vue as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import Session from '@grantstreet/psc-vue/utils/session.js'
import { Severity } from '@sentry/types'

// Make sure these are unique
export const sentryCodes = {
  E_WALLET_RETRY: '39',
  DYNAMIC_IMPORT_REJECT: '953',
}

export type Level = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'

// A helper that does what it says on the tin.
const formatComponentName = (instance: ComponentPublicInstance) =>
  `component <${instance.$options.__name || 'anonymous'}>` +
  (instance.$options.__file ? ` at ${instance.$options.__file}` : '')

export type SentryMessageFunction = (
  error: Error,
  options?: {
    level?: Level
    code?: string
    context?: object
  }
) => void

type clientObserver = (client: Hub) => void

/**
 * Initializes a new Sentry Client. We set up a custom Hub instead of calling
 * Sentry.init() because otherwise we conflict with the Raven JS that REx
 * includes on their page (and E-Wallet doesn't load).
 *
 * XXX: Does REx Still use Raven? We should probably keep this safety measure
 * anyway.
 *
 * This method adds its own `beforeSend` handler, so you cannot pass one of your
 * own at this point.
 *
 * @returns a new sentry client.
 * @throws {TypeError} - Throws if no dsn is provided.
 */
const newSentryClient = (
  clientOptions: Omit<BrowserOptions, 'beforeSend'>,
): Hub => {
  // Full options, including `beforeSend`
  const fullOptions: BrowserOptions = clientOptions

  if (!fullOptions.dsn) {
    throw new TypeError('Warning: Sentry DSN not provided.')
  }

  fullOptions.ignoreErrors ||= []
  fullOptions.ignoreErrors.push(...[
    /PADDINGX' is undefined$/,
    'The operation was canceled by the user.',
    /QuotaExceededError/,
  ])

  // This has to return `event` or `null`, not a boolean
  fullOptions.beforeSend = event => {
    // Only send events for production builds (including netlify sandboxes)
    if (process.env.NODE_ENV === 'development') {
      console.error('Not sending sandbox error to Sentry:', event)
      return null
    }
    return event
  }

  // Injected by webpack
  fullOptions.release ||= process.env.SENTRY_RELEASE_ID
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Looks like the type is complaining about not having private methods?
  // I don't know why the Sentry type was constructed this way,
  // but I don't think this matters.
  return new Hub(new BrowserClient(fullOptions))
}

/**
 * Helper for skipping errors that should not be logged and avoiding
 * double-logging the same error/message to Sentry. There are also other
 * mechanisms (ignoreErrors, beforeSend) for ignoring errors.
 *
 * @returns false if this has already been logged or
 * object.suppressLogging === true. Returns true otherwise (or if
 * typeof object !== 'object')
 */
const shouldLog = (
  object: (
    Error & {
      suppressLogging?: boolean
      alreadyLogged?: boolean
    }
  ) | string,
): boolean => {
  if (!object || typeof object !== 'object') {
    return true
  }
  if (object?.suppressLogging) {
    return false
  }
  if (object?.alreadyLogged) {
    return false
  }

  object.alreadyLogged = true

  return true
}

const logSentryException = (
  error: Error | string,
  {
    level = 'error',
    code,
    context,
  }: {
    level?: Level
    code?: string
    context?: object
  } = {},
  client: Hub,
) => {
  if (!shouldLog(error)) {
    return
  }

  console.error(error)

  // Wrap raw strings Errors to get stack traces
  if (typeof error === 'string') {
    error = new Error(error)
  }

  client.setTag('error_code', code || 'none')

  client.setTag('url', window.location.href)
  client.setTag('user-agent', navigator.userAgent)
  client.setTag('session_id', Session.id)

  // This sets a scope that won't be reused on following events
  client.withScope(scope => {
    if (context) {
      for (const [key, value] of Object.entries(context)) {
        // Contexts must be objects
        scope.setContext(key, value)
      }
    }

    scope.setLevel(Severity.fromString(level))
    client.captureException(error)
  })
}

/**
 * This creates sentry exception helpers that will log to the passed project
 * DSNs, automatically using the correct environment. ***This means you don't
 * have to initialize the global Sentry client with the correct environment at
 * import time.***
 *
 * This lazily initializes the Sentry client because we don't necessarily know
 * the GSG_ENVIRONMENT when this factory function is called.
 *
 * @returns an object with Sentry helpers
 */
export const sentryFactory = ({
  dsns,
  clientOptions,
  onNewClient,
}: {
  dsns: {
    /**
     * A prod env sentry dsn
     */
    prod: string
    /**
     * A nonprod env sentry dsn
     */
    nonprod: string
  }

  /**
   * Sentry client confirmation options, excluding `dsn` which should be passed
   * via the separate `dsns` parameter.
   */
  clientOptions?: Omit<BrowserOptions, 'dsn'>

  /**
   * A function accepting a newly created sentry client, where the consumer can
   * attach additional metadata. This will be called each time a new sentry
   * client is created (lazy [re]initialized).
   */
  onNewClient?: clientObserver
}): {
  sentryException: SentryMessageFunction
  vueErrorHandler: Plugin
  chainClient: (observer: clientObserver) => void
} => {
  let client
  let lastEnv
  const clientObservers: clientObserver[] = []

  if (onNewClient) {
    clientObservers.push(onNewClient)
  }

  // Always get a client with the correct configs
  const getClient = () => {
    // If the env changed (because we called this at least once before it was
    // set), then re-init with the new env
    if (!client || lastEnv !== GSG_ENVIRONMENT) {
      lastEnv = GSG_ENVIRONMENT

      let dsn
      let environment
      let known = true
      try {
        dsn = isProd() ? dsns.prod : dsns.nonprod
        environment = GSG_ENVIRONMENT
      }
      catch (error) {
        // GSG_ENVIRONMENT isn't initialized, default to prod
        // TODO: Can we add meta to indicate that we don't actually know the env
        // sometimes?
        dsn = dsns.prod
        environment = environmentStrings.prod
        known = false
      }

      client = newSentryClient({
        dsn,
        environment,
        ...clientOptions,
      })

      client.setTag('environment_known', String(known))

      // Allow consumers access to each client when it's set up
      for (const observe of clientObservers) {
        observe(client)
      }
    }

    return client
  }

  const sentryException: SentryMessageFunction = (error, options) =>
    logSentryException(error, options, getClient())

  return {
    sentryException,
    vueErrorHandler: {
      install (app) {
        // If there are multiple modules with their own copy of this sentry module
        // and a single (external) copy of vue, then log and throw.
        //
        // Things that will cause this exception:
        // - Calling this function multiple times in the same module.
        // - Calling this function in multiple standalone modules.
        //
        // If it would potentially be necessary to have multiple entry points to
        // calling this function the caller is responsible for either doing a
        // manual check for Vue.config.errorHandler or wrapping in a try/catch.
        if (app.config.errorHandler) {
          console.info(app.config.errorHandler)
          throw new Error('Tried adding multiple Vue error handlers.')
        }

        // Attach this directly to a vue instance's errorCaptured lifecycle hook
        // to handle errors for that instance and all descendants. This is useful
        // in cases like widget bundles where there is no root level app like
        // GovHub to capture errors.
        app.config.errorHandler = (
          error: unknown,
          instance: ComponentPublicInstance | null,
          info: string,
        ) => {
          const errorInstance = error instanceof Error ? error : new Error(`${error}`)
          const metadata: {
            propsData?: ComponentPublicInstance['$options']['propsData']
            componentName?: string
            lifecycleHook?: string
          } = {}

          metadata.propsData = instance?.$options.propsData

          if (instance) {
            metadata.componentName = formatComponentName(instance)
          }

          if (typeof info !== 'undefined') {
            metadata.lifecycleHook = info
          }

          // This timeout makes sure that any breadcrumbs are recorded before
          // sending it off to sentry
          // TODO: Clarification? Explanation?
          setTimeout(() => {
            sentryException(errorInstance, {
              context: {
                vue: metadata,
              },
            })
          })

          console.warn(`Error in ${info}: '${errorInstance.toString()}'`, instance)

          // return false to stop propagation to higher level error handlers and
          // avoid duplicate error reports
          return false
        }
      },
    },

    // Adds a new function which will receive access to each sentry new client
    // created.
    chainClient: (observer: clientObserver) => {
      clientObservers.push(observer)
      observer(client || getClient())
    },
  }
}
