import { getClientsAndSites, loadConfig } from '@grantstreet/psc-config'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import { currentBaseUrl } from '../static-build-helpers.ts'

// Parses the client and site from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted. The `site` returned
// might be undefined or not actually a site if this is a client-only-url site
// (e.g., /sacramento or /sacramento/utilities-search).
export const clientAndSiteFromUrl: () => { client?: string, site?: string } = () => {
  let path: string | null = window.location.pathname

  if (path === '/') {
    // Try to get to the last-used client/site and hydrate the url
    path = safeLocalStorage.getItem('lastPayHubPath')
    if (path && !path?.endsWith('.html')) {
      window.history.replaceState(null, '', path)
    }
  }

  const parts = window.location.pathname.split('/').filter(part => part)

  // Some paths cannot possibly be a client/site, so return nothing and
  // do not cache. We may be called in the future after our location has
  // changed (with/without navigation, a la `history.replaceState()`)
  // For example, /callback is always a Login OIDC callback
  if (parts[currentBaseUrl() === '/' ? 0 : 3] === 'callback') {
    return {}
  }

  // Stash the client/site and return it
  if (path && !path.endsWith('.html')) {
    safeLocalStorage.setItem('lastPayHubPath', path)
  }

  const hasDmv = window.location.pathname.includes('/dmv/')
  const urlIndexAdjustment = Number(hasDmv)

  // Keep in sync with @grantstreet/psc-js/utils/routing.js>formatParams()
  // Note: The currentBaseUrl() part can stay different since it involves
  // taking the client/site from the URL.
  return {
    app: hasDmv ? 'dmv' : '',
    // Trying to locate the client/site on regular GovHub vs. a static build
    client: (parts[(currentBaseUrl() === '/' ? 0 : 3) + urlIndexAdjustment] || '').toLowerCase(),
    site: (parts[(currentBaseUrl() === '/' ? 1 : 4) + urlIndexAdjustment] || '').toLowerCase(),
  }
}

export async function setupConfig () {
  const { client, site } = clientAndSiteFromUrl()
  await getClientsAndSites()
  await loadConfig({ client, site })
}
