import { sentryCodes, dynamicImportHandlerFactory } from '@grantstreet/psc-vue/utils/logging.ts'
import { sentryException } from './sentry.js'

export const handleDynamicImport = dynamicImportHandlerFactory({
  app: 'govhub-ui',
  logException: (error) => {
    sentryException(error, {
      level: 'error',
      tags: {
        'error_code': sentryCodes.DYNAMIC_IMPORT_REJECT,
      },
    })
  },
})
