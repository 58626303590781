
import type { App } from 'vue'
import { useGsgUser } from '@grantstreet/user'
import { searchPayablesPath } from '@grantstreet/payables'
import { sentryException } from '../sentry.js'
import type { Store } from 'vuex'
import type { Router } from 'vue-router'

export const setupCallbackActions = (app: App<Element>, { store, router }: { store: Store<Record<string, unknown>>, router: Router }) => {
  const { user } = useGsgUser(app)
  return {
    // This one is associated with MSI, but since it is concerned with navigation,
    // it seems to make more sense to live with the "main app" in
    // @grantstreet/govhub-vue.
    savePayable: async ({ payablePath = '' } = {}) => {
      if (!user || !payablePath) {
        return
      }
      if (!user.loggedIn) {
        console.warn('Cannot complete savePayable action. User is not logged in.')
        return
      }

      // Search payable paths can throw an exception, but since this is being
      // called in the callback action, we can reasonably expect that the payable
      // should exist. If we don't receive the payable, we can't add it to MSI
      // anyway.
      const payable = await searchPayablesPath({ path: payablePath })

      if (!payable) {
        sentryException(new Error(`Couldn't find payable for "savePayable" action. Path was: ${payablePath}`))
        return
      }

      try {
        // Loading the user's saved items can fail if the request times out, or
        // if the payable paths saved are no longer valid. This will result in a
        // thrown exception, that we should catch. This will ensure that the
        // new payable is successfully saved to MSI
        await store.getters['MyItems/loadPromise']
      }
      catch (error) {
        // We expect to hit these exceptions while SBC is undergoing data
        // migration. There would be no actionable response to this error, so we
        // will silently swallow this error
      }

      await store.dispatch('MyItems/addToMyItems', { payable })

      // Note: This triggers an uncaught exception in vue-router, when it tries
      // to invoke our "beforeEach" guard. This doesn't appear to prevent
      // "replace" from doing what it's supposed to do. This occurs because the
      // beforeEach guard modifies the resulting destination url, helpfully
      // filling in the missing 'site' parameter (due to the order of route
      // matching). Since the end url doesn't match the expected end url,
      // vue-router errors.
      router.replace({ name: 'my-dashboard' }).catch((error) => {
        // @ts-expect-error This is broken but I haven't had a chance to fix
        // yet. Silencing TS so I can push for now
        if (error?.type !== NavigationFailureType.redirected) {
          // If this navigation error is not a redirection error, re-throw
          throw error
        }
      })
    },
  }
}
