import Vue from 'vue'
import { createStore } from 'vuex'
import createApiModule from './api.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import noCard from '../../announcements/noCard.json'
import noBank from '../../announcements/noBank.json'
import noBankOrCard from '../../announcements/noBankOrCard.json'
import noPayment from '../../announcements/noPayment.json'
import loginIssues from '../../announcements/loginIssues.json'
import { sentryException } from '../sentry.js'
import { getFirstValidScheduleDateForClient } from '@grantstreet/psc-js/utils/date.js'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { logHelperFactory } from '@grantstreet/psc-vue/utils/logging.ts'
import { getPayableSource } from '@grantstreet/payables'
import { configState, configGetters } from '@grantstreet/psc-config'
import { snakeCase } from '@grantstreet/psc-js/utils/cases.js'
import { currentBaseUrl, isOnStaticSandbox } from '../static-build-helpers.ts'
import { redirectNavLinks } from '../utils.ts'
import { getCountyTaxesUrl, getGovHubUrl } from '@grantstreet/psc-environment'
import { countyTaxes, getFilteringContext, dmv } from '../filtering-context-helpers.ts'

const addPrefixingAndRedirecting = (rawNavLink, route, router) => {
  // The logic here is pretty intense but there are a bunch of different
  // cases we need to account for. Instead of have a large switch statement
  // we're building the links piece by piece based on conditions.
  // an important gotcha: we need to be aware if we're on county-taxes.net
  // bc we've been redirected from the payable source (property-taxes in CA
  // for example) or bc it's part of the base url of the county (like FL counties)

  const navLink = { ...rawNavLink }

  navLink.key = `${navLink.path ? navLink.path + '-' : ''}${navLink.name || navLink.link}-${navLink.title?.en}`

  // there is no special filtering that will happen on this site
  // simply return the navLink
  if (!Object.keys(configGetters.payablesActionControlLists).length) {
    return navLink
  }

  // if the navlink is an absolute redirect link simply return the navLink
  // we will not be modifying those
  if (
    navLink.link &&
    (navLink.type === 'redirect' || navLink.type === 'taxsys-navigation-link') &&
    navLink.link.startsWith('http')
  ) {
    return navLink
  }

  // the filtering context determines what we will
  // be showing as filtered results in my dashboard
  // the filtering context currently can be one of three
  // values: pex, dmv, redirectToCountyTaxes
  // each of those values matches a key in an object generated
  // in payablesActionControlLists, a part of the psc-config store
  //
  // dmv will show all the payables that have the productOwnership of 'dmv'
  // redirectToCountyTaxes will show all payables that have the productOwnership of 'tax'
  // pex will show all the payables that were filtered out in either dmv || redirectToCountyTaxes
  const filteringContext = getFilteringContext(route)

  // since both FL counties and CA TS counties will both be on
  // county-taxes, we'll need to know if we're on county-taxes
  // because we've been redirected there via a nav link (ie CA)
  // or we are there b/c that is the natural location of the site (FL)
  const isBeingRedirectedToCountyTaxes = filteringContext === countyTaxes

  // getting the filtering context via the getFilteringContext
  // util it makes it quicker and easier to debug bc it's possible to mock the
  // present location via changing the value of a flag on the window.
  // b/c of that we're not looking at route.params.app to determine
  // if we're in /dmv/. if you'd like to support multiple prefixes
  // you'll need to change the logic here.
  const filterContextApp = filteringContext === dmv ? dmv : ''

  // it is a nav link WITH a prefix
  const doesNavLinkHaveAnAppPrefix = (navLink.urlAppMapping === 'prefixWithApp') && navLink.productOwnership

  // it is a nav link WITH a prefix
  // we DO NOT have a prefix in the browser
  // when clicked it will ADD the prefix
  // the base url is the current window's url
  const shouldAddAppPrefixToUrl = doesNavLinkHaveAnAppPrefix && (navLink.productOwnership !== filterContextApp)

  // it is a nav link WITHOUT a prefix
  // we DO have a prefix in the browser
  // when clicked it will REMOVE the prefix
  // the base url is the current window's url
  const shouldRemoveAppPrefixFromUrl = !doesNavLinkHaveAnAppPrefix && Boolean(filterContextApp)

  // the base url IS the current window's url
  const doesLinkToCurrentLocation = shouldAddAppPrefixToUrl || shouldRemoveAppPrefixFromUrl

  // we are currently being redirected to county-taxes
  // this is a nav link that will take us from county-taxes back to govhub
  const doesLinkToGovHub = isBeingRedirectedToCountyTaxes &&
    (navLink.urlAppMapping !== 'redirectToCountyTaxes')

  // we are currently on govhub
  // this is a nav link that redirect us to county-taxes
  const doesLinkToCountyTaxes = !isBeingRedirectedToCountyTaxes &&
    (navLink.urlAppMapping === 'redirectToCountyTaxes')

  let link = ''

  // this first conditional gets the base of the url
  if (doesLinkToCountyTaxes) {
    // will never have the prefix
    link = isOnStaticSandbox() ? 'https://dev3.county-taxes.net' : getCountyTaxesUrl()
  }
  else if (doesLinkToGovHub) {
    link = isOnStaticSandbox() ? 'https://dev3.govhub.com' : getGovHubUrl()
  }
  else if (doesLinkToCurrentLocation) {
    // note: we're using the location and not currentBaseUrl
    // b/c currentBaseUrl will add the version AND router.resolve
    // also adds the version if in a sandbox
    link = window.location.origin
  }

  // this is a special case for certain dmv forms that have
  // redirect links but the are relative links. we will
  // still need to add the prefix if its payable has it.
  if (
    navLink.link &&
    navLink.type === 'redirect' &&
    !navLink.link.startsWith('http')
  ) {
    link = isOnStaticSandbox() ? link + currentBaseUrl().replace(/\/$/, '') : link
    navLink.link = link + (navLink.productOwnership ? `/${navLink.productOwnership}` : '') + navLink.link
    link = ''
  }

  // adds the rest of the link to the base
  // if link has a value it will be an absolute link
  // and as such the type should now be 'redirect'.
  // redirect links by default have an icon indicating that
  // you'll be taken away from the site.
  // hideRedirectIcon removes that icon.
  if (link) {
    const { client, site } = route.params
    const params = {
      client,
      site,
    }
    if (doesNavLinkHaveAnAppPrefix) {
      params.app = navLink.productOwnership
    }
    const { href } = router.resolve({
      name: navLink.name,
      params,
    })
    link = `${link}${href}`
    delete navLink.name
    navLink.type = 'redirect'
    navLink.link = link
    navLink.hideRedirectIcon = true
  }

  return navLink
}

export default ({
  user,
  updateUserData,
}) => {
  // Do not import anything here that also imports ../router.js, because that
  // will lead to a circular dependency and the store will be undefined in
  // router.js.

  let logDiagnosticsAction
  let logRequestAction

  const announcements = {
    noCard,
    noBank,
    noBankOrCard,
    noPayment,
    loginIssues,
  }

  const store = createStore({
    modules: {
      PayHub: {
        namespaced: true,

        state: {
          // TODO: Remember *all* properties on the state object need to be
          // initialized here!
          eWalletKey: 0,
          dataVaultToken: null,
          // E-Wallet JWT
          encodedJwt: null,
          authPromise: null,

          routes: [],

          // Set when there's an announcement to display
          hasAnnouncement: false,

          signedInToNonprod: {},

          locale: 'en',

          // The following flags are set by the govhub installer.
          enableHeader: true,
          enableFooter: true,
          enableHomePage: true,
          enableFloatingCart: true,
          showAnnouncements: true,
          enableMySettingsModification: true,
        },

        getters: {

          navLinks: state => (route, router) => {
            return [
              ...state.routes.map(route => ({
                icon: route.meta?.navIcon,
                title: route.meta?.title,
                name: route.name,
                order: route.meta?.navOrder,
                linkInNav: route.meta?.linkInNav,
                isSearchPage: route.meta?.isSearchPage,
                productOwnership: route.meta?.productOwnership,
                urlAppMapping: route.meta?.urlAppMapping,
              })),
              ...redirectNavLinks(),
            ]
              // Move nav link filtering here to account for all nav links
              // (including search & redirect)
              .filter(navLink => navLink.linkInNav)
              .map(navLink => addPrefixingAndRedirecting(navLink, route, router))
              .sort((a, b) => a.order - b.order)
          },

          // Return true to allow a user to navigate away from PH when clicking
          // a redirect link. Return false to show a warning modal
          shouldAllowRedirect: (state, getters, rootState, rootGetters) => () =>
            user.loggedIn || rootGetters['Cart/items'].length === 0,

          locale: state => state.locale,
          isMultilingual: (state, getters, rootState) => Boolean(
            configState.config?.payHub?.additionalLanguages?.length,
          ),
          prodUrl: (state, getters, rootState) => {
            const { client, site } = configState.config
            return `${window?.location?.origin}/${client}/${site}`
          },

          signedInToNonprod: state => state.signedInToNonprod,

          firstValidScheduleDateForClient: (state, getters, rootState) => getFirstValidScheduleDateForClient(configState.config.cart.timeZone),

          hasAnnouncement: state => state.hasAnnouncement,

          pexClientName: (state, getters, rootState) => {
            return configState.config.paymentExpress.clientName
          },

          cardAuthBillApperance: (state, getters, rootState) => {
            return configState.config.cart.temporaryAuthStatementDescriptionCards
          },

          // Whether to show a survey after checkout
          showReceiptSurvey: (state, getters, rootState) => configState.config.cart.showReceiptSurvey,
          // Whether to show a survey after scheduling/canceling a payment
          showScheduleCreationSurvey: (state, getters, rootState) => configState.config.schedPay.showScheduleCreationSurvey,
          showScheduleCancellationSurvey: (state, getters, rootState) => configState.config.schedPay.showScheduleCancellationSurvey,

          // Branding
          clientTitle: (state, getters, rootState) => configState.config.payHub?.clientTitle,
          clientLogo: (state, getters, rootState) => configState.config.payHub?.clientLogo,
          clientCover: (state, getters, rootState) => configState.config.payHub.landingPageImage,
          favicon: (state, getters, rootState) => configState.config.payHub?.favicon,

          // Jotform or Dev-Built Forms on /forms/$formID
          formConfigs: () => {
            const jotformConfigs = Array.isArray(configState.config.forms?.formConfigurations) ? configState.config.forms.formConfigurations : []
            const devBuiltConfigs = Array.isArray(configState.config.forms?.devBuiltFormConfigurations) ? configState.config.forms.devBuiltFormConfigurations : []
            jotformConfigs.forEach(obj => {
              obj.type = 'jotform'
            })
            devBuiltConfigs.forEach(obj => {
              obj.type = 'devBuilt'
            })
            const combinedFormConfigs = [...jotformConfigs, ...devBuiltConfigs]
            return combinedFormConfigs
          },

          contactEntity: (state, getters, rootState, rootGetters) => displayType => {
            const departmentTitle = (
              getPayableSource(displayType) ||
              configGetters.payableSources[0]
            )?.departmentDisplayName
            return (`${getters.clientTitle} ${departmentTitle?.[state.locale] || ''}`).trim()
          },

          // Initialization promises
          authPromise: state => {
            if (state.authPromise) {
              return state.authPromise
            }
            throw new Error('authPromise is not initialized')
          },

          // Gets the URL to the client website. Looks up the URL from the
          // payable source for the passed display type.
          clientUrl: (state, getters, rootState, rootGetters) => displayType => {
            const sources = configGetters.payableSources
            if (!sources.length) {
              sentryException('Cannot get client URL for site with zero payable sources')
              return
            }

            let source
            if (displayType) {
              source = getPayableSource(displayType)
              if (!source) {
                sentryException(`Cannot find client url: No payable source found for display type "${displayType}".`)
                return
              }
            }
            else {
              // No display type passed, so default to the first (and hopefully
              // only) payable source. We can remove this once we refactor
              // returnUrl to support multi-dept redirects.
              source = sources[0]
              if (sources.length > 1) {
                sentryException(`Cannot disambiguate client URL from multiple payable sources when no displayType is passed. Using source for display type "${source.displayType}".`)
              }
            }

            return source.clientWebsiteUrl
          },

          // XXX: We'll need to update this once we support multi-dept redirects
          returnUrl: (state, getters, rootState, rootGetters) => {
            const urls = rootGetters['Cart/urls']
            return (urls && urls.return) ||
              configState.config.payHub.returnHomeUrl || getters.clientUrl()
          },

          // Returns all applicable fee structures based on the passed items or
          // the cart items.
          translatedFeeKeys: (state, getters, rootState, rootGetters) => items => {
            items = items || rootGetters['Cart/cart']?.items
            return [...new Set(
              items.map(item => `translated-fee-keys.${item.payable.itemCategory.toLowerCase()}`)
                .filter(item => i18n.global.te(item)),
            )]
          },

          /**
           * Returns function that accepts a string argument 'pexDepartment' and
           * searches for this department. If no department is found this will
           * return undefined. If a department is found the payment limit object
           * in the following form:
           *  {
           *    pexDepartment: 'CUBS',
           *    cardLimit: '500.00',
           *    bankLimit: '', // currently unused
           *    paymentLimitDelay: {
           *      en: '72 hours',
           *      es: '72 horas',
           *    },
           *  }
           * @returns { (s: string) => object | undefined }
           */
          getPaymentLimits: (state, getters, rootState, rootGetters) => pexDepartment => {
            const limits = configState.config.cart.paymentLimits || []
            let limit
            if (pexDepartment) {
              limit = limits.find(limit => limit?.pexDepartment === pexDepartment)
            }

            const sources = configGetters.payableSources

            // If there is only one payable source, it's a redirect, and there
            // is one payment limit, return that payment limit.
            // This is to support the limit-exceeded page where we want to show
            // the payment limit on redirect but don't have a payable to look up
            // the PEx department on.
            //
            // TODO PSC-3857 Remove this.
            // TODO: What happens when we have no limits and a multi-redirect site
            // like al-jefferson/permits?
            if (
              !limit &&
              sources?.length === 1 &&
              sources[0].sourceType === 'redirect' &&
              limits?.length === 1
            ) {
              limit = limits[0]
            }

            if (typeof limit?.paymentLimitDelay === 'string') {
              // The limit is a raw string (untranslated). This can go away once
              // PSC-4985 is in prod and we update existing payable sources to
              // use the translated version.
              limit.paymentLimitDelay = {
                en: limit.paymentLimitDelay,
                es: '',
              }
            }
            return limit
          },

          itemCategories: (state, getters, rootState) =>
            configState.config.cart.itemCategories || [],

          statementDescriptions: (state, getters) => itemCategory => {
            const configSource = getters.itemCategories.find(
              category => category.itemCategory === itemCategory,
            ) || {}

            return {
              cardPrimary: configSource.primaryAmountStatementDescriptionCards,
              cardFee: configSource.feeStatementDescriptionCards,
              bankPrimary: configSource.primaryAmountStatementDescriptionBanks,
              bankFee: configSource.feeStatementDescriptionBanks,
              paypalPrimary: configSource.primaryAmountStatementDescriptionPayPal,
            }
          },

          // Accepts a $route object and returns the URL we want to redirect the
          // user back to after they log out. If redirectHomeOnLogout is false,
          // returns undefined.
          logoutRedirectUrl: (state, getters) => ({ params: { client, site }, meta: { redirectHomeOnLogout } }) => {
            if (!redirectHomeOnLogout) {
              return
            }
            const redirect = `${window.location.origin}${currentBaseUrl()}${client}/${site ? site + '/' : ''}`
            const siteUsesHomepage = getters.siteUsesHomepage
            return redirect + (siteUsesHomepage ? '' : 'checkout')
          },
        },

        mutations: {
          setRoutes (state, routes) {
            state.routes = routes
          },

          setDataVaultToken (state, dataVaultToken) {
            state.dataVaultToken = dataVaultToken
          },

          setSignedInToNonprod (state, { siteKey, val }) {
            state.signedInToNonprod[siteKey] = val
          },

          setEncodedJwt (state, encodedJwt) {
            state.encodedJwt = encodedJwt
          },

          // XXX Does this cause memory leaks?
          rerenderEWallet (state) {
            state.eWalletKey = state.eWalletKey + 1
          },

          setAuthPromise (state, promise) {
            Vue.set(state, 'authPromise', promise)
          },

          // DO NOT use this generally. Use the action `setLocale`
          // This is only for the install script to set the locale from storage
          setLocaleLight (state, locale) {
            state.locale = locale
          },
        },

        actions: {
          // Dispatch this to update the flags used to install govhub. This
          // should only be called once - by the govhub installer.
          setInstallFlags ({ state }, flags) {
            for (const flag in flags) {
              state[flag] = flags[flag]
            }
          },

          // Sets the user's language preference
          async setLocale ({ state, dispatch }, { locale, updateUser = true }) {
            if (updateUser) {
              await dispatch('setUserProfile', { language: locale })
            }

            try {
              window.localStorage.setItem('payhubDefaultLocale', locale)
            }
            catch (error) {
              console.error('Cannot access local storage due to incognito window')
            }

            i18n.global.locale.value = locale
            EventBus.$emit('payhub.localeChanged', locale)
            state.locale = locale
          },

          async setUserPhone ({ dispatch }, phone) {
            return dispatch('setUserProfile', { phone })
          },

          /**
           * Update the user's name in their login service profile. This accepts
           * their input given/family name and then also creates the "name"
           * value used for display.
           */
          async setUserName ({ dispatch }, update) {
            return dispatch('setUserProfile', update)
          },

          async setContactPreference ({ dispatch }, contactPreference) {
            return dispatch('setUserProfile', { contactPreference })
          },

          /**
           * Set a user's profile fields all at once. Only passed fields will be
           * updated. Available fields are:
           *
           * - givenName
           * - familyName
           * - phone
           * - contactPreference
           * - language
           *
           * Keys not set won't be changed.
           *
           * This should be the preferred method of updating a user's
           * profile. Sending too many different updates at once can cause sync
           * issues in Okta's database (see PSC-12245).
           */
          async setUserProfile ({ state, getters, rootGetters }, data) {
            if (!user.loggedIn) {
              return
            }

            // Don't mutate original object
            data = { ...data }

            // Clear these if an invalid value is passed clear that field
            if (data.contactPreference && !['email', 'sms'].includes(data.contactPreference)) {
              console.error(`Attempted to set invalid contact preference: ${data.contactPreference}`)
              data.contactPreference = undefined
            }
            if (data.language && !['en', 'es'].includes(data.language)) {
              console.error(`Attempted to set invalid language: ${data.language}`)
              data.language = undefined
            }

            // Update the "name" field if the given/family names have changed
            if (data.givenName && data.familyName) {
              data.name = `${data.givenName} ${data.familyName}`
            }

            const apiData = {}
            const gsgLoginData = {}
            // Only set passed fields
            for (const [key, value] of Object.entries(data)) {
              apiData[snakeCase(key)] = value
              gsgLoginData[key] = value
            }

            await rootGetters['API/login'].updateUser(user.id, apiData)
            return updateUserData(gsgLoginData)
          },

          // name - name of announcement to enable
          async setAnnouncement ({ state, rootGetters }, { name }) {
            const announcement = announcements[name]

            if (!announcement) {
              state.hasAnnouncement = false
              return
            }

            // This means that the config must be loaded
            if (name === 'loginIssues' && !configGetters.useLogin) {
              // This particular banner would be confusing on sites that don't
              // offer login in the first place.
              state.hasAnnouncement = false
              return
            }

            state.hasAnnouncement = true
          },

          // Logs diagnostic information to Kibana.
          // Depends on VueCookies and API/requestService being installed
          logDiagnostics: ({
            state,
            getters,
            rootState,
            rootGetters,
          }, data) => {
            if (!logDiagnosticsAction) {
              const api = rootGetters['API/requestService']
              if (!api) {
                console.error('Error: No request service API set')
                return
              }

              logDiagnosticsAction = logHelperFactory({
                logData: api.diag.bind(api),
                isDiagnostic: true,
              })
            }
            const { client, site } = configState.config
            return logDiagnosticsAction({
              data,
              defaultClient: client,
              defaultSite: site,
              // Append the email, since the id is opaque
              appUser: user.email ? `${user.id} - ${user.email}` : user.id,
            })
          },

          // Depends on VueCookies and API/requestService being installed
          logRequest: ({
            state,
            getters,
            rootGetters,
          }, data) => {
            if (!logRequestAction) {
              const api = rootGetters['API/requestService']
              if (!api) {
                console.error('Error: No request service API set')
                return
              }

              logRequestAction = logHelperFactory({
                logData: api.logRequest.bind(api),
                isDiagnostic: false,
              })
            }

            return logRequestAction({
              data,
              // Append the email, since the id is opaque
              appUser: user.email ? `${user.id} - ${user.email}` : user.id,
            })
          },

        },
      },
    },
  })

  store.registerModule('API', createApiModule({
    getLocale: () => store.getters['PayHub/locale'],
    user,
  }))

  return store
}
