import type { GoogleAnalytics } from '../embedded-demo/EmbeddedDemoUtils.ts'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import { isProd, overrideTaxCbsServiceUrl, overrideTaxsysDomainForwardingUrl } from '@grantstreet/psc-environment/environment.js'
import type { Store } from 'vuex'
import type { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import type { App } from 'vue'
import { sentryException } from '../sentry.js'

type embeddedAttachPayload = {
  googleAnalytics: GoogleAnalytics
  language: string
  tcbServiceUrl: string
  rootTaxSysIframeUrl: string
  // This is deprecated. We should remove it as soon as we can confirm no
  // clients are using the deprecated parent whisperer. It represents a
  // development and testing override for sandboxes from before we refactored
  // our TaxSys URL generation logic.
  // TODO: PSC-23276 - Remove this
  localTaxSysUrl: string
}

export function setupEmbeddedParentWhisperer () {
  // This embeddedAttachPromise will be resolved by the embeddedParentWhisperer
  // when the attach message is received.
  let resolveEmbeddedAttachPromise: (value: embeddedAttachPayload) => void
  const embeddedAttachPromise: Promise<embeddedAttachPayload> = new Promise(resolve => {
    resolveEmbeddedAttachPromise = resolve
  })
  // If this is an "embedded" govhub installation, this whisperer will be used
  // to communicate with the parent wrapper script.
  // see @grantstreet/govhub-ui/src/main-embedded-wrapper.ts
  // This parent whisperer must be created before the iframe load event is
  // fired, otherwise the wrapper attach message will get dropped.
  const embeddedParentWhisperer = new iframeWhisperer.ToParent({
    // autoHeight must be false since this is a standalone whisperer for full
    // govhub installations
    autoHeight: false,
    messageSource: 'embedded-public-site',
    actions: {
      // The attach message is sent from the wrapper script. Currently, it
      // does nothing. It will include some required information such as
      // google analytics information in the future
      'embeddedPublicSite.attach': (payload: embeddedAttachPayload) => {
        resolveEmbeddedAttachPromise(payload)
      },
    },
  })
  return {
    embeddedAttachPromise,
    embeddedParentWhisperer,
  }
}

// TODO PSC-23056: Convert IframeWhisperer library to TypeScript and use those defintions.
type ParentIframeWhisperer = {
  startHandlingHeight: () => void
  notify: ({ id, action, payload, err }: { id?: string, action: string, payload: Record<string, unknown>, err?: string | Error }) => void
}

export async function setupEmbeddedPublicSite (app: App<Element>,
  {
    store,
    bus,
    embeddedParentWhisperer,
    embeddedAttachPromise,
  }: {
    store: Store<Record<string, unknown>>
    bus: EventBus
    embeddedParentWhisperer: ParentIframeWhisperer
    embeddedAttachPromise: Promise<embeddedAttachPayload>
  }) {
  // verify that this exists within an iframe
  // This value is passed to the installShared function. Once the vue router has
  // been constructed, if this evaluates to true, the user will be redirected to
  // the load-failure page
  if (window.self === window.top) {
    throw new Error('The embedded public site can only be installed in an iframe. No iframe was detected.')
  }
  // Start handling height changes to prevent a double scrollbar in the iframe
  // containing the public site.
  embeddedParentWhisperer.startHandlingHeight()
  // Before the embedded public site can be installed, the embedded wrapping
  // script must whisper an 'attach' message that includes google analytics
  // data in its payload. This message should already have been sent by this
  // point. The installer will wait a maximum of 30 seconds before giving up
  // and mounting the load failure page.
  const attachTimeout = new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new Error('For govhub to install as an embedded public site, the installer must receive an attach event from the embedded wrapper script. The installer did not receive any events after 30 seconds.'))
    }, 30_000)
  })

  const {
    // localTaxSysUrl is deprecated. We should remove it as soon as we can
    // confirm no clients are using the deprecated parent whisperer.
    // TODO: PSC-23276 - Remove this
    localTaxSysUrl,

    googleAnalytics,
    tcbServiceUrl = localTaxSysUrl,
    language,
    rootTaxSysIframeUrl,
  } = await Promise.race([embeddedAttachPromise, attachTimeout]) as embeddedAttachPayload

  if (typeof rootTaxSysIframeUrl === 'string') {
    overrideTaxsysDomainForwardingUrl(rootTaxSysIframeUrl, true)
  }
  if (typeof tcbServiceUrl === 'string') {
    if (isProd()) {
      sentryException(
        new Error('Overriding the TCB Service URL in prod is NOT recommended. This is likely a mistake.'),
        {
          level: 'error',
        },
      )
    }
    overrideTaxCbsServiceUrl(tcbServiceUrl)
  }

  // Set the language. This will be overridden by the installShared function if
  // a user is logged in - with their last used language.
  store.dispatch('PayHub/setLocale', {
    locale: language,
    $i18n: i18n,
    updateUser: false,
  })

  bus.$on('routeChange', newRoute =>
    embeddedParentWhisperer.notify({
      action: 'routeChange',
      payload: newRoute.fullPath,
    }),
  )

  // We can emit this to tell the parent page to navigate to a new url
  bus.$on('navigateEpsParent', url =>
    embeddedParentWhisperer.notify({
      action: 'navigate',
      payload: url,
    }),
  )

  // Provide the whisperer for components to use for propagating child
  // iframe messages to the parent iframe when needed.
  // This is used by the TaxSysIframe to bubble up the request to scroll
  // the page to a given #fragment.
  app.provide('parentWhisperer', embeddedParentWhisperer)

  store.dispatch('PayHub/setInstallFlags', {
    enableHeader: false,
    enableFooter: false,
    showAnnouncements: false,
    enableHomePage: false,
    enableFloatingCart: false,
    enableMySettingsModification: false,
  })
  return googleAnalytics
}
