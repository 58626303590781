<template>
  <component
    :is="isRedirect ? 'a' : 'router-link'"
    v-bind="linkProps"
    v-if="formattedParams.client"
    @mouseover="hoverOn"
    @mouseleave="hoverOff"
    @click="isRedirect ? onRedirectClick($event, navLink.link) : $emit('close-menu')"
  >
    <slot
      v-if="!noIcons"
      name="icon-before"
      :is-hovered="isHovered"
    >
      <svgicon
        :name="navLink.icon"
        color="#666"
        height="1.3rem"
        width="1.3rem"
        class="mr-4 mr-sm-5"
      />
    </slot>
    <slot
      :is-redirect="isRedirect"
      :is-hovered="isHovered"
      :text="text"
    >{{ text }}</slot>
    <slot
      v-if="!noIcons && !navLink.hideRedirectIcon"
      name="icon-after"
      :is-redirect="isRedirect"
      :is-hovered="isHovered"
    >
      <svgicon
        v-if="isRedirect"
        name="goto-xsmall"
        color="#666"
        height="0.7rem"
        width="0.7rem"
        class="mr-4 mr-sm-5 ml-2"
      />
    </slot>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  emits: ['redirect-click', 'close-menu'],
  props: {

    navLink: {
      type: Object,
      required: true,
      validator: ({ name, icon, title, link }) => (name || link) && icon && title,
    },
    noIcons: {
      type: Boolean,
      defualt: false,
    },
    shouldRedirect: {
      type: Function,
      default: link => link,
    },

  },

  data: () => ({
    isHovered: false,
  }),

  computed: {
    text () {
      return this.navLink.title[this.$i18n.locale] || ''
    },

    isRedirect () {
      return this.navLink.type === 'redirect' || this.navLink.type === 'external' || this.navLink.type === 'taxsys-navigation-link'
    },

    linkProps () {
      const { name, link, type } = this.navLink

      // Four types of links are possible. See the GHA router for documentation.
      if (type === 'external') {
        // :to is required but unused
        return { href: link, target: '_blank', to: {} }
      }
      if (type === 'redirect' || type === 'taxsys-navigation-link') {
        return { href: link, to: {} }
      }
      // Typically not used in GH Public
      if (link) {
        return { to: link }
      }

      const to = {
        name,
        params: {
          app: this.formattedParams.app,
          client: this.formattedParams.client,
          site: this.formattedParams.site,
        },
      }

      return { to }
    },

    ...mapGetters('router', [
      'formattedParams',
    ]),
  },

  methods: {
    onRedirectClick (e, link) {
      const openInNewTab = e.ctrlKey || e.shiftKey || e.metaKey || e.button === 1

      // We only care about stopping the link if they are leaving in their
      // current tab because they might lose their cart in certain
      // circumstances.
      if (this.shouldRedirect(link) || openInNewTab) {
        return
      }

      e.preventDefault()
      this.$emit('redirect-click', link)
    },

    hoverOn () {
      this.isHovered = true
    },

    hoverOff () {
      this.isHovered = false
    },
  },
}
</script>
